<template>
  <div>
    <div v-if="isMobile" class="mobile-container">
      <router-view />
    </div>
    <div v-else class="login">
      <div class="left-container">
        <div class="wrapper">
          <div class="logo" style="margin-top: 70px">
            <Icon name="LogoPortTranzit" width="485px" height="auto" />
          </div>
        </div>
      </div>
      <div class="right-container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { mapGetters } from 'vuex'
import Icon from '../UI/icon/Icon'

export default {
  name: 'Login',
  components: { Icon },
  data() {
    return {
      title: 'ПОРТ ТРАНЗИТ',
    }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
  },
}
</script>

<style lang="sass">
$bgc-body: #f7f8fa
$title-font-size: 34px

body
  margin-top: 0
  margin-left: 0
  background-color: $bgc-body
html, body
  height: 100%

.mobile-container
  width: 100vw
  height: 100vh
  background: url("~@/assets/bg/mobile-bg.jpg") no-repeat
  -webkit-background-size: cover
  -moz-background-size: cover
  -o-background-size: cover
  background-size: cover
.login
  height: 100vh
  display: flex
  align-items: center
  &>div
    width: 50%
  & .left-container
    background: url("~@/assets/bg/truck-new.jpg") top .8% left 9%/auto 100% no-repeat #ccc
    background-size: cover
    height: 100%
    text-align: center
    & .wrapper
      padding: 20px
      & .title
        color: #fff
        font-family: Ubuntu-Bold,Arial,sans-serif
        font-size: $title-font-size
  & .right-container
    //padding: 20px
</style>
