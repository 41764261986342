var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isMobile
      ? _c("div", { staticClass: "mobile-container" }, [_c("router-view")], 1)
      : _c("div", { staticClass: "login" }, [
          _c("div", { staticClass: "left-container" }, [
            _c("div", { staticClass: "wrapper" }, [
              _c(
                "div",
                { staticClass: "logo", staticStyle: { "margin-top": "70px" } },
                [
                  _c("Icon", {
                    attrs: {
                      name: "LogoPortTranzit",
                      width: "485px",
                      height: "auto",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "right-container" }, [_c("router-view")], 1),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }