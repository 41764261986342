// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/bg/mobile-bg.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/bg/truck-new.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "/* Colors */\n/** Colors redesign **/\n/* spaces */\n/* Buttons */\n/* Inputs */\n/* Font Size */\n/* Line height */\n/* Transition */\n/* Borders */\n/* Box shadow */\nbody {\n  margin-top: 0;\n  margin-left: 0;\n  background-color: #f7f8fa;\n}\nhtml, body {\n  height: 100%;\n}\n.mobile-container {\n  width: 100vw;\n  height: 100vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n}\n.login {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n}\n.login > div {\n  width: 50%;\n}\n.login .left-container {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") top 0.8% left 9%/auto 100% no-repeat #ccc;\n  background-size: cover;\n  height: 100%;\n  text-align: center;\n}\n.login .left-container .wrapper {\n  padding: 20px;\n}\n.login .left-container .wrapper .title {\n  color: #fff;\n  font-family: Ubuntu-Bold, Arial, sans-serif;\n  font-size: 34px;\n}", ""]);
// Exports
module.exports = exports;
